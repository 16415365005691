import React from 'react'

import { Heading3, Heading2, BodyText2, BodyText1 } from '@lumoslabs/lumosity-storybook'
import Head from 'next/head'
import Link from 'next/link'
import styled from 'styled-components'

import Footer from '~/components/Footer'
import TopNav from '~/components/TopNav'
import PageContent from '~/components/ui/PageContent'
import { useGetUserQuery } from '~/graphql/generated/schema'
import useLocalDate from '~/hooks/useLocalDate'
import useLocale from '~/hooks/useLocale'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import AppStoreBadge from '~/images/MobileApps/app-store-badge.svg'
import GooglePlayBadge from '~/images/MobileApps/google-play-badge.svg'
import MobileDeviceImage from '~/images/MobileApps/mobile-apps.svg'

const MobileDevicesInterstitial = (): JSX.Element => {
  const t = useTranslationForNamespace('common')
  const { dateFormatted } = useLocalDate()
  const { minNumberOfGamesForLocale } = useLocale()
  const { data: userQueryData } = useGetUserQuery()
  const name = userQueryData?.me?.firstName
  const greeting = t(`interstitials.mobileDevices.greeting`, { name: name })

  return (
    <>
      <Head>
        <title>{t('interstitials.mobileDevices.title')}</title>
      </Head>
      <TopNav />
      <PageContent>
        <PageContent.Header>
          <BodyText2>{dateFormatted}</BodyText2>

          <StyledHeading2>{greeting}</StyledHeading2>
          <Heading3 as='h3'>{t('interstitials.mobileDevices.header')}</Heading3>
        </PageContent.Header>
        <PageContent.Main>
          <div>
            <MobileDeviceImage title='mobile-apps' width='100%' height='100%' />
            <BadgeRow>
              <BadgeContainer>
                {/*TODO: localize the SVG for all marketplaces */}
                <Link href='https://play.google.com/store/apps/details?id=com.lumoslabs.lumosity' passHref={true}>
                  <GooglePlayBadge title='google-play-badge' width='100%' height='100%' />
                </Link>
              </BadgeContainer>
              <BadgeContainer>
                {/*TODO: localize the SVG for all marketplaces */}
                <Link href='https://apps.apple.com/app/lumosity-brain-training/id577232024' passHref={true}>
                  <AppStoreBadge title='app-store-badge' width='100%' height='100%' />
                </Link>
              </BadgeContainer>
            </BadgeRow>
            <CaptionContainer>
              <StyledBodyText1>
                {t('interstitials.mobileDevices.body', { minNumberOfGames: minNumberOfGamesForLocale })}
              </StyledBodyText1>
            </CaptionContainer>
          </div>
        </PageContent.Main>
      </PageContent>
      <Footer />
    </>
  )
}

const BadgeContainer = styled.div`
  width: 150px;
  margin-right: 15px;
  cursor: pointer;
`

const BadgeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ theme }) => theme.mediaQuery.minWidth.tablet} {
    justify-content: flex-start;
  }
`
const StyledHeading2 = styled(Heading2)`
  line-height: 48px;
  display: block;
`
const StyledBodyText1 = styled(BodyText1)`
  text-align: center;
`
const CaptionContainer = styled.div`
  margin-top: 16px;
`

export default MobileDevicesInterstitial
